<template>
  <va-card class="row mx-3 pb-5">
    <va-app-bar gradient class="pa-3">
      <va-button
        :to="{ name: 'my-chats' }"
        :icon="backArrow"
        color="#fff"
        flat
        :rounded="false"
      />
      <div class="row align--center px-3">
        <va-avatar :src="$store.state.imgUrl + champ.logo"> </va-avatar>
        <div class="col px-2">
          <h4 style="color: white" class="mb-2">{{ champ.name }}</h4>
          <span v-if="name" style="color: white">{{
            name + " is typing ..."
          }}</span>
          <span v-else class="contacts mb-1">
            <span>Me, </span>
            <span>{{ champ.creator?.name }}, </span>
            <span
              v-for="participant in champ.participants"
              :key="participant.user.id"
              >{{ participant.user?.name }},
            </span>
          </span>
        </div>
      </div>
      <va-spacer />
    </va-app-bar>

    <div class="va-chat px-1 pb-3">
      <div
        style="height: 60vh !important"
        v-sticky-scroll="{
          animate: true,
          duration: 500,
        }"
        id="chatbody"
      >
        <va-infinite-scroll
          :load="getMoreMessages"
          reverse
        ></va-infinite-scroll>
        <div v-if="messages.length > 0">
          <div
            class="va-chat__message"
            v-for="(message, index) in messages"
            :style="{
              backgroundColor:
                message.sender?.id == user_id ? theme.primary : undefined,
            }"
            :key="index"
            :class="{
              'va-chat__message--yours': message.sender?.id == user_id,
            }"
          >
            <div
              class="row my-2 align--center"
              v-if="message.sender?.id != user_id"
            >
              <va-avatar></va-avatar>
              <span class="px-2">{{ message.sender?.name }}</span>
            </div>
            <div class="va-chat__message-text py-2" v-if="!message.media_type">
              <span class="py-2 px-2">{{ message.message }}</span>
              <div
                class="py-2 text-end"
                v-moment="{
                  date: message.created,
                  locale: $i18n.locale == 'sa' ? 'ar' : 'en',
                }"
              ></div>
            </div>
            <div class="va-chat__message-text py-2" v-else>
              <img
                class="fluid-img"
                v-if="message.media_type == 'image'"
                :src="$store.state.imgUrl + message.media"
                alt="img"
              />
              <video
                class="fluid-video"
                v-else
                :src="$store.state.imgUrl + message.media"
                controls
              ></video>
              <div
                class="py-2 text-end"
                v-moment="{
                  date: message.created,
                  locale: $i18n.locale == 'sa' ? 'ar' : 'en',
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="text--center mt-5" v-else>
          <span style="font-size: 36px" class="material-icons px-3">
            speaker_notes_off
          </span>
          <h1>No messages yet ..</h1>
        </div>
      </div>
      <form @submit.prevent="sendMessage" class="va-chat__controls">
        <va-input
          v-model="inputMessage"
          v-on:keyup="onTyping"
          placeholder="Type your message..."
          class="va-chat__input mr-2"
        />
        <div class="adminActions">
          <input type="checkbox" name="adminToggle" class="adminToggle" />
          <va-file-upload
            v-model="media"
            hidden
            type="single"
            @update:model-value="previewMedia($event)"
            id="attach"
          />
          <a class="adminButton" href="#!">
            <span class="material-icons"> attach_file </span>
          </a>
          <div class="adminButtons">
            <a @click="attachFile('image')" title="image">
              <span class="material-icons"> collections </span>
            </a>
            <a @click="attachFile('video')" title="video">
              <span class="material-icons"> video_library </span>
            </a>
          </div>
        </div>
        <transition name="bounce">
          <va-button gradient flat v-if="inputMessage" @click="sendMessage">
            <span class="material-icons px-2"> send </span>
          </va-button>
        </transition>
      </form>
    </div>
  </va-card>

  <!-- For Media preview -->
  <va-modal @cancel="clearMedia" hide-default-actions v-model="showMediaModal">
    <slot>
      <img v-if="media_type == 'image'" id="img" class="fluid-img" />
      <video
        v-if="media_type == 'video'"
        id="vid"
        class="fluid-video"
        controls
       />
    </slot>
    <template #footer>
      <va-button
        text-color="#0e5"
        icon="share"
        gradient
        @click="sendMessage"
      ></va-button>
      <va-button color="#f00" flat icon="close" @click="clearMedia"></va-button>
    </template>
  </va-modal>
</template>

<script>
import { gql, request } from "graphql-request";
import StickyScroll from "./StickyScroll";
import { useGlobalConfig } from "vuestic-ui";
import io from "@/services/web_socket";

export default {
  name: "chat",
  directives: { StickyScroll },
  data() {
    return {
      inputMessage: "",
      media: [],
      media_type: "",
      showMediaModal: false,
      champ: {},
      messages: [],
      newMessagesCount: 0,
      counter: 5,
      name: null,
      page: 0,
      limit: 15,
      messagesCount: null,
      offset: 0,
      user_id: JSON.parse(localStorage["userData"])?.id,
      user_name: JSON.parse(localStorage["userData"])?.name,
      sending: false,
    };
  },
  async mounted() {
    await this.getMessages();
    const chatbody = document.getElementById("chatbody");
    // chatbody.scrollTo({ top: chatbody.clientHeight * 10 });
    io.listen(`chat_champ_${this.$route.params.id}`).subscribe((data) => {
      const chatbody = document.getElementById("chatbody");
      chatbody.scrollTo({ top: chatbody.clientHeight * 10 });
      if (data.sender_id == this.user_id) data.yours = true;
      this.messages.push(data);
    });
    io.listen(`chat_champ_received_${this.$route.params.id}`).subscribe(
      (data) => {
        this.newMessagesCount++;
        for (let i = 0; i < this.messages.length; i++) {
          if (this.messages[i].id == data.chat_id) {
            this.messages[i].receivers.push(data);
          }
        }
      }
    );
    io.listen(`keyup_champ_${this.$route.params.id}`).subscribe((name) => {
      this.name = name;
      this.counter = 5;
    });
    setInterval(() => {
      this.name = null;
      if (this.counter > 0) {
        this.counter--;
      }
    }, 2000);
  },
  methods: {
    async sendMessage() {
      if (!this.inputMessage && this.media_type == "") {
        return;
      }
      const SEND_MESSAGE = gql`
        mutation (
          $sender_id: Int
          $champ_id: Int
          $message: String
          $media_type: String
          $media: Upload
        ) {
          SendMessage(
            message: {
              sender_id: $sender_id
              champ_id: $champ_id
              message: $message
              media: $media
              media_type: $media_type
            }
          ) {
            success
          }
        }
      `;

      // For Message Only
      // if(this.media_type == ""){
      //   var variables =
      //   return
      // }
      // For media Only
      // if(this.media_type != ""){
      //   var variables = {
      //     sender_id: +this.user_id,
      //     champ_id: +this.$route.params.id,
      //     message: this.inputMessage,
      //     this.media_type
      //   }
      //   return
      // }

      this.sending = true;
      const response = await request(this.$store.state.appUrl, SEND_MESSAGE, {
        sender_id: +this.user_id,
        champ_id: +this.$route.params.id,
        message: this.inputMessage,
        media: this.media[0],
        media_type: this.media_type,
      });
      this.inputMessage = "";
      this.clearMedia();
      this.sending = false;
      const chatbody = document.getElementById("chatbody");
      // chatbody.scrollTo({ top: chatbody.clientHeight * 10 });
    },
    onTyping() {
      if (this.counter == 0) {
        io.keyup({
          champ_id: this.$route.params.id,
          name: this.user_name,
          id: +this.user_id,
        });
      }
    },
    async getMessages() {
      const GET_MESSAGES = gql`
        query ($editor_id: Int, $champ_id: Int, $limit: Int, $offset: Int) {
          Chats(
            where: { champ_id: $champ_id }
            order: "reverse:created"
            limit: $limit
            offset: $offset
          ) {
            sender {
              id
              name
            }
            message
            media
            media_type
            created
          }
          Champs(id: $champ_id) {
            name
            logo
            creator {
              name
            }
            participants: champ_editors(
              where: { user_id: { ne: $editor_id } }
            ) {
              user {
                id
                name
              }
            }
          }
          ChatsCount(where: { champ_id: $champ_id }) {
            count
          }
        }
      `;
      const response = await request(this.$store.state.appUrl, GET_MESSAGES, {
        champ_id: +this.$route.params.id,
        editor_id: +this.user_id,
        limit: this.limit,
        offset: this.offset,
      });
      this.champ = response.Champs[0];
      this.messages = response.Chats.reverse();
      this.messagesCount = response.ChatsCount.count;
    },
    async getMoreMessages() {
      if (this.messagesCount > this.messages.length) {
        const chatbody = document.getElementById("chatbody");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.offset = this.page * this.limit + this.newMessagesCount;
        const GET_MESSAGES = gql`
          query ($champ_id: Int, $limit: Int, $offset: Int) {
            Chats(
              where: { champ_id: $champ_id }
              order: "reverse:created"
              limit: $limit
              offset: $offset
            ) {
              sender {
                id
                name
              }
              message
              media
              media_type
              created
            }
            ChatsCount(where: { champ_id: $champ_id }) {
              count
            }
          }
        `;
        const response = await request(this.$store.state.appUrl, GET_MESSAGES, {
          champ_id: +this.$route.params.id,
          limit: this.limit,
          offset: this.offset,
        });

        this.page++;
        for (let i = 0; i < response.Chats.length; i++) {
          this.messages.unshift(response.Chats[i]);
        }
        this.messagesCount = response.ChatsCount.count;
        // chatbody.scroll({ top: chatbody.height });
      }
    },
    attachFile(type) {
      const fileInput = document.querySelector("#attach input");
      if (type == "image") {
        fileInput.setAttribute("accept", "image/*");
        this.media_type = "image";
      }
      if (type == "video") {
        fileInput.setAttribute("accept", "video/*");
        this.media_type = "video";
      }
      fileInput.click();
    },
    previewMedia(e) {
      if (this.media.length > 0) {
        this.showMediaModal = true;
        setTimeout(() => {
          let file = this.media[0];
          let blobURL = URL.createObjectURL(file);
          if (this.media_type == "video") {
            document.querySelector("#vid").src = blobURL;
            return;
          }
          if (this.media_type == "image") {
            document.querySelector("#img").src = blobURL;
            return;
          }
        }, 0);
      }
    },
    clearMedia() {
      this.media = [];
      this.media_type = "";
      this.showMediaModal = false;
    },
  },
  computed: {
    theme() {
      return useGlobalConfig().getGlobalConfig().colors;
    },
    backArrow() {
      return (this.$i18n.locale == "gb") | "en" ? "west" : "east";
    },
  },
};
</script>

<style lang="scss">
.contacts {
  color: white;
  font-size: 0.725rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 350px;
}
// .chat {
//   &__content {
//     @include va-flex-center();
//   }
// }

$chat-message-br: 0.875rem;

.va-chat {
  width: 100%;

  &__body {
    min-height: 18.75rem;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    overflow-y: auto;
  }

  &__message {
    position: relative;
    padding: 0.657rem 1.375rem;
    margin-bottom: 0.625rem;
    max-width: 80%;
    width: fit-content;
    overflow-wrap: break-word;
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    align-self: flex-start;
    background: gainsboro;

    &-text {
      display: block;
      transform: translateY(-2px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--yours {
      color: white;
      align-self: flex-end;
      border-top-right-radius: 0;
      border-top-left-radius: 0.5rem;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    padding: 0 2rem;
  }

  &__input {
    margin-bottom: 0;
  }
}
// Animation
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.fluid-img {
  max-width: 100%;
  object-fit: cover;
}
.fluid-video {
  max-width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}

// FAB
.adminActions {
  position: relative;
  bottom: 0;
  right: 0;
}
body.ar .adminActions {
  right: 10px;
}

.adminButton {
  height: 40px;
  width: 40px;
  background: linear-gradient(to right, rgb(19, 128, 205), rgb(21, 79, 193));
  border-radius: 50%;
  display: block;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
}

.adminButton span {
  font-size: 22px;
}

.adminButtons {
  position: absolute;
  width: 100%;
  bottom: 120%;
  text-align: center;
}

.adminButtons a {
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-decoration: none;
  margin: 10px auto 0;
  line-height: 1.15;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  position: relative;
  box-shadow: 0 0 5px 1px rgba(51, 51, 51, 0.3);
}

.adminButtons a:hover {
  transform: scale(1.05);
}

.adminButtons a:nth-child(1) {
  cursor: pointer;
  background: linear-gradient(to right, #5f2926, #ff4c3f);
  transition: opacity 0.2s ease-in-out 0.3s, transform 0.15s ease-in-out;
}
.adminButtons a:nth-child(2) {
  cursor: pointer;
  background: linear-gradient(to right, #0f402a, #009654);
  transition: opacity 0.2s ease-in-out 0.25s, transform 0.15s ease-in-out;
}

.adminActions a span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.adminToggle {
  -webkit-appearance: none;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1.2rem;
  z-index: 2;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 3px 5px 1px rgba(51, 51, 51, 0.3);
}

.adminToggle:hover {
  box-shadow: 0 3px 6px 2px rgba(51, 51, 51, 0.3);
}

.adminToggle:checked ~ .adminButtons a {
  opacity: 1;
  visibility: visible;
}
</style>
